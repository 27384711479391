import React from 'react';
import HomePage from './Homepage';
import { Client } from 'appwrite';
const client = new Client();

client
    .setEndpoint('http://sv-erbernbabben-appwrite.video.wukibude.de/v1')
    .setProject('6418548e9579bcc81215');
function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
