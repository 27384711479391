import React from 'react';
import myImage from './IMG_1036.jpg'; // import the image file
import './Homepage.css'; // import the stylesheet

function HomePage() {
  return (
    <div className="container">
      <div className="text-container">
        <h1>Welcome to SV-Erbernbabben!</h1>
        <p>Hier entsteht in Kürze die Online-Präsenz des SV-Erbernbabben.</p>
      </div>
      <div className="image-container">
        <img src={myImage} alt="SV-Erbernbabben" className="center-image" />
      </div>
      
    </div>
  );
}

export default HomePage;
